
import FormPage from '../pages/form.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/form/',
    component: FormPage,
  },


  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
