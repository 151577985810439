<template>
  <f7-page name="form">
    <f7-navbar :title="headtitle" subtitle="Prevent Tool"></f7-navbar>
  <f7-block v-show="!hidesurvey" class="no-padding">
       <div  v-if="only!='research'">
     <f7-row>
    <f7-col class="marginAuto" width="100" medium="80">
       <f7-list >
   <f7-list-item class="neurita" title="Physical Activity" >
   </f7-list-item>
 </f7-list>
 <!--
  <f7-block>
    <p>Think about all the <strong>vigorous</strong> activities that you did in the <strong>last 7 days.  Vigorous</strong> physical activities refer to activities that take hard physical effort and make you breathe much harder than normal.  Think only about those physical activities that you did for at least 10 minutes at a time.</p>
  </f7-block>
  <f7-card class="cajabor" :class="{respondida:((!noVigorous && vigorous.days!=0) || noVigorous)}">
      <f7-card-header>
      <p>During the <strong>last 7 days</strong>, on how many days did you do <strong>vigorous</strong> physical activities like heavy lifting, digging, aerobics, or fast bicycling?</p>
      </f7-card-header>
      <f7-card-content :padding="false" >
        <f7-row :no-gap="true">
          <f7-col width="100" medium="45"  :class="{bluebellow:!noVigorous}" >
            <f7-row :no-gap="true">
              <f7-col>
          <f7-list>
         <f7-list-input
                      :disabled="noVigorous"
                      outline
                      type="select"
                      :value="vigorous.days||0"
                      placeholder="Days"
                      @input="setVigorousOrModerateValue({vigorousOrModerate:'vigorous',value:$event.target.value,which:'days'})"
                      >
                      <option v-for="(day,index) in 8" :key="index"
                       :value="index">{{ index }}</option>
                    </f7-list-input>
          </f7-list>
          </f7-col>
          <f7-col>
            <f7-list :class="{disabled:noVigorous}">
              <f7-list-item  class="nextToNumber">
          Days per week
              </f7-list-item>
            </f7-list>
          </f7-col>
            </f7-row>
        
        </f7-col>
        <f7-col width="100" medium="10" >
          <f7-list>
            <f7-list-item>or</f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col width="100" medium="45" :class="{bluebellow:noVigorous}" >
          <f7-list>
          <f7-list-item class="sinwhitespace" @change="noVigorous=$event.target.checked"  checkbox title="No vigorous physical activities" :checked="noVigorous"></f7-list-item>
        </f7-list>
        </f7-col>
      </f7-row>
      </f7-card-content>
      </f7-card>
      <f7-card class="cajabor" :class="{respondida:((vigorous.hours!=0 || vigorous.mins!=0) )}"
       v-show="!noVigorous && vigorous.days!=0">
      <f7-card-header>
      <p>How much time did you usually spend doing <strong>vigorous</strong> physical activities on one of those days?</p>
      </f7-card-header>
      <f7-card-content >
         <f7-row>
          <f7-col  :class="{bluebellow:vigorous.hours!=0 || vigorous.mins!=0}" >
            <f7-row>
                <f7-col>
               <f7-list>
                <f7-list-input
                      outline
                      type="select"
                      :value="vigorous.hours||0"
                      placeholder="Hours"
                      @input="setVigorousOrModerateValue({vigorousOrModerate:'vigorous',value:$event.target.value,which:'hours'})"
                      >
                      <option v-for="(hour,index) in 13" :key="index"
                       :value="index">{{ index }}</option>
                    </f7-list-input>
               </f7-list>
              </f7-col>
              <f7-col class="align-self-center">
                Hours per day
              </f7-col>
              <f7-col>
               <f7-list>
                <f7-list-input
                      outline
                      type="select"
                      :value="vigorous.minutes||0"
                      placeholder="Minutes"
                      @input="setVigorousOrModerateValue({vigorousOrModerate:'vigorous',value:$event.target.value,which:'minutes'})"
                      >
                      <option v-for="min in minutesEvery5" :key="min"
                       :value="min">{{ min }}</option>
                    </f7-list-input>
               </f7-list>
              </f7-col>
              <f7-col class="align-self-center">
                Minutes per day
              </f7-col>
              </f7-row>
        
        </f7-col>
      </f7-row>
      </f7-card-content>
      </f7-card>
        -->
      <f7-block>
    <p>Think about all the <strong>moderate</strong> activities that you did in the <strong>last 7 days.  Moderate</strong> activities refer to activities that take moderate physical effort and make you breathe somewhat harder than normal, like a brisk walk.<!--activities refer to activities that take moderate physical effort and make you breathe somewhat harder than normal.  Think only about those physical activities that you did for at least 10 minutes at a time.--></p>
  </f7-block>
   <f7-card class="cajabor" :class="{respondida:((!noModerate && moderate.days!=0) || noModerate)}">
      <f7-card-header>
        <p>How many days of moderate (or greater) activity do you do per week?</p>
      <!--<p>During the <strong>last 7 days</strong>, on how many days did you do <strong>moderate</strong> physical activities like carrying light loads, bicycling at a regular pace, or doubles tennis?  Do not include walking.</p>-->
      </f7-card-header>
      <f7-card-content >
         <f7-row :no-gap="true">
          <f7-col  width="100" medium="45"    :class="{bluebellow:!noModerate}" >
            <f7-row :no-gap="true">
              <f7-col>
                <f7-list>
         <f7-list-input
                      :disabled="noModerate"
                      outline
                      type="select"
                      :value="moderate.days||0"
                      placeholder="Days"
                      @input="setVigorousOrModerateValue({vigorousOrModerate:'moderate',value:$event.target.value,which:'days'})"
                      >
                      <option v-for="(day,index) in 8" :key="index"
                       :value="index">{{ index }}</option>
                    </f7-list-input>
          </f7-list>
          </f7-col>
          <f7-col>
            <f7-list>
              <f7-list-item 
              :class="{disabled:noModerate}"
               class="nextToNumber">
          Days per week
              </f7-list-item>
            </f7-list>
          </f7-col>
            </f7-row>
        
        </f7-col>
        <f7-col  width="100" medium="10"   >
          <f7-list>
            <f7-list-item>or</f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col  width="100" medium="45"   :class="{bluebellow:noModerate}" >
          <f7-list>
          <f7-list-item @change="noModerate=$event.target.checked" class="sinwhitespace" checkbox title="No moderate physical activities" :checked="noModerate"></f7-list-item>
        </f7-list>
        </f7-col>
      </f7-row>
      </f7-card-content>
        
      </f7-card>
      <f7-card  class="cajabor" :class="{respondida:( (moderate.hours!=0 || moderate.mins!=0)) }"
      v-show="!noModerate && moderate.days!=0">
      <f7-card-header>
     <!-- <p>How much time did you usually spend doing <strong>moderate</strong> physical activities on one of those days?</p> -->
     <p>How much time did you usually spend doing moderate (or greater) activities on those days?</p>
      </f7-card-header>
      <f7-card-content >
         <f7-row >
          <f7-col :class="{bluebellow:moderate.hours ||moderate.mins}" >
              <f7-row>
                <f7-col>
               <f7-list>
                <f7-list-input
                      outline
                      type="select"
                      :value="moderate.hours||0"
                      placeholder="Hours"
                      @input="setVigorousOrModerateValue({vigorousOrModerate:'moderate',value:$event.target.value,which:'hours'})"
                      >
                      <option v-for="(hour,index) in 13" :key="index"
                       :value="index">{{ index }}</option>
                    </f7-list-input>
               </f7-list>
              </f7-col>
              <f7-col class="align-self-center">
                Hours per day
              </f7-col>
              <f7-col>
               <f7-list>
                <f7-list-input
                      outline
                      type="select"
                      :value="moderate.minutes||0"
                      placeholder="Minutes"
                      @input="setVigorousOrModerateValue({vigorousOrModerate:'moderate',value:$event.target.value,which:'minutes'})"
                      >
                      <option v-for="min in minutesEvery5" :key="min"
                       :value="min">{{ min }}</option>
                    </f7-list-input>
               </f7-list>
              </f7-col>
              <f7-col class="align-self-center">
                Minutes per day
              </f7-col>
              </f7-row>
        
        </f7-col>
      </f7-row>
      </f7-card-content>
      </f7-card>
  <f7-list >
   <f7-list-item class="neurita" title="Food Intake" >
   </f7-list-item>
 </f7-list>
      <f7-card class="separatecards cajabor" 
        v-for="foodIntake in foodIntakes" :key="foodIntake.id"
        :class="{respondida:diet[foodIntake.id].value!==null}" 
        >
        <f7-card-header>  
            <p>{{foodIntake.question }}</p>
        </f7-card-header>
  <f7-card-content>
    <div >
      
              <f7-row >
                <f7-col>
                  <f7-list>
                <f7-list-input
                      outline
                      type="select"
                      :value="diet[foodIntake.id].value==0?0:diet[foodIntake.id].value||''"
                      @input="setDietSertvingsValue({wich:foodIntake.id, value:$event.target.value})"
                      >
                      <option v-for="(serving,i) in 11" :key="i" 
                      :value="i" >{{ i }}</option>
                    </f7-list-input>
                  </f7-list>
                </f7-col>
                <f7-col class="align-self-center width-fit-content">per</f7-col>
                <f7-col>
                <f7-list>
                <f7-list-input
                      outline
                      type="select"
                      :value="diet[foodIntake.id].period||''"
                      @input="setDietPeriodValue({wich:foodIntake.id, value:$event.target.value})"
                      >
                      <option value="day" >day</option>
                      <option value="week" >week</option>
                    </f7-list-input>
                  </f7-list>
                </f7-col>
                <f7-col />
                <f7-col />
              </f7-row>
              <p v-if="foodIntake.serving">({{ foodIntake.serving }})</p>
            </div>

  </f7-card-content>
</f7-card>
<f7-list >
   <f7-list-item class="neurita" title="Nicotine exposure" >
   </f7-list-item>
 </f7-list>
<f7-card class="separatecards cajabor" 
        :class="{respondida:smoking.value!==null}" 
        >
        <f7-card-header>  
            <p>What is your smoking status?</p>
        </f7-card-header>
  <f7-card-content>
    <f7-list>
      <f7-list-input
                      outline
                      type="select"
                      :value="smoking.value"
                      placeholder="Smoking status"
                      @input="setNicotineExposureValue({value:$event.target.value})"
                      >
                      <option :value="null">Select</option>
                      <option v-for="(exposure,index) in nicotineExposures[childOrAdult]" :key="index"
                       :value="index">{{ exposure.text }}</option>
                    </f7-list-input>
                    <f7-list-item
                    checkbox
                    @change="setDiscountCheckbox({checked:$event.target.checked,wich:'smoking'})"
                    :checked="smoking.discountCheckbox"
                    title="I live with an active indoor smoker"
                    ></f7-list-item>
    </f7-list>
  </f7-card-content>
</f7-card>
<f7-list >
   <f7-list-item class="neurita" title="Sleep" >
   </f7-list-item>
 </f7-list>
 
<f7-card class="separatecards cajabor" 
        :class="{respondida:sleep.hours!==null||sleep.minutes!==null}" 
        >
        <f7-card-header>  
            <p>On average, how many hours of sleep do you get in a 24 hour period?</p>
        </f7-card-header>
  <f7-card-content>
    <p>Think about the time you actually spend sleeping or napping, not just the amount of sleep you think you should get</p>
                  <f7-row  class="margin-top">
                    <f7-col>
                     <f7-list>
                      <f7-input
                      outline
                      type="select"
                      :value="sleep.hours"
                      placeholder="Hours"
                      @input="setSleepingValues({value:$event.target.value,type:'hours'})"
                      >
                      <option :value="0">{{ 0 }}</option>
                      <option v-for=" hour in 18" :key="hour"
                       :value="hour">{{ hour }}</option>
                    </f7-input>
                  </f7-list>
                    </f7-col>
                    <f7-col class="align-self-center">Hours</f7-col>
                    <f7-col>
                     <f7-list>
                      <f7-input
                      outline
                      type="select"
                      :value="sleep.minutes"
                      placeholder="Minutes"
                      @input="setSleepingValues({value:$event.target.value,type:'minutes'})"
                      >
                      <option v-for=" min in minutesEvery5" :key="min"
                       :value="min">{{ min }}</option>
                    </f7-input>
                  </f7-list>
                    </f7-col >
                    <f7-col class="align-self-center">Minutes</f7-col>
                  </f7-row>
  </f7-card-content>
</f7-card>

<f7-list >
   <f7-list-item class="neurita" title="Medications" >
   </f7-list-item>
 </f7-list>
 <f7-card class="separatecards cajabor" 
        :class="{respondida:Object.keys(bloodPressure).length>0}" 
        >
        <f7-card-header>Are you taking medication to lower your blood pressure?</f7-card-header>
  <f7-card-content>
    <f7-list>
    <f7-list-item class="margin-top"
          radio
          name="bloodPressureMedications"
          title="Yes"
          @change="setDiscountCheckbox({checked:$event.target.checked,wich:'bloodPressure'})"
                    :checked="bloodPressure.discountCheckbox"
          ></f7-list-item>
          <f7-list-item class="margin-top"
          radio
          name="bloodPressureMedications"
          title="No"
          @change="setDiscountCheckbox({checked:!$event.target.checked,wich:'bloodPressure'})"
                    :checked="Object.keys(bloodPressure).length>0 && bloodPressure.discountCheckbox!==true"
          ></f7-list-item>
        </f7-list>
  </f7-card-content>
</f7-card>
 <f7-card class="separatecards cajabor" 
        :class="{respondida:Object.keys(cholesterol).length>0}" 
        >
        <f7-card-header>Are you taking medication to lower your cholesterol?</f7-card-header>
  <f7-card-content>
    <f7-list>
    <f7-list-item class="margin-top"
          radio
          name="cholesterolMedications"
          title="Yes"
          @change="setDiscountCheckbox({checked:$event.target.checked,wich:'cholesterol'})"
                    :checked="cholesterol.discountCheckbox"
          ></f7-list-item>
          <f7-list-item class="margin-top"
          radio
          name="cholesterolMedications"
          title="No"
          @change="setDiscountCheckbox({checked:!$event.target.checked,wich:'cholesterol'})"
                    :checked="Object.keys(cholesterol).length>0 && cholesterol.discountCheckbox!==true"
          ></f7-list-item>
        </f7-list>
  </f7-card-content>
</f7-card>


     <f7-list >
   <f7-list-item class="neurita" title="Motivation to change" >
   </f7-list-item>
 </f7-list>
 <f7-block>
    <p>For each question, please choose the one response that best reflects how you feel. We are interested in your opinion and there are no right or wrong answers.</p>
    <p><strong>1= not at all interested , 2= slightly interested 3= fairly interested 4= interested and 5=very interested</strong></p>
  </f7-block>
      <f7-card class="separatecards">

  <f7-card-content>
     <f7-list media-list class="maslineas">
     <f7-list-item class="cajabor" :class="{respondida:willingPhysical!==null}">
     <div  slot="title">How interested are you to make changes in your <strong>physical activity</strong> in order to be healthier?</div>
      <div slot="text" class="margin-top display-flex justify-content-space-between"><span>Not at all interested</span><span>Very interested</span></div>
     <f7-segmented class="btns"  raised tag="p">
    <f7-button    @click="willingPhysical=1" :active="willingPhysical==1">1</f7-button>
    <f7-button    @click="willingPhysical=2" :active="willingPhysical===2">2</f7-button>
    <f7-button    @click="willingPhysical=3" :active="willingPhysical===3">3</f7-button>
    <f7-button    @click="willingPhysical=4" :active="willingPhysical===4">4</f7-button>
    <f7-button    @click="willingPhysical=5" :active="willingPhysical===5">5</f7-button>
  </f7-segmented>
     </f7-list-item>
     <f7-list-item class="cajabor" :class="{respondida:willingDiet!==null}" >
     <div slot="title">How interested are you to make changes in your <strong>eating habits</strong> in order to be healthier?</div>
     <div slot="text" class="margin-top display-flex justify-content-space-between"><span>Not at all interested</span><span>Very interested</span></div>
     <f7-segmented  raised tag="p">
    <f7-button    @click="willingDiet=1" :active="willingDiet==1">1</f7-button>
    <f7-button    @click="willingDiet=2" :active="willingDiet===2">2</f7-button>
    <f7-button    @click="willingDiet=3" :active="willingDiet===3">3</f7-button>
    <f7-button    @click="willingDiet=4" :active="willingDiet===4">4</f7-button>
    <f7-button    @click="willingDiet=5" :active="willingDiet===5">5</f7-button>
  </f7-segmented>
     </f7-list-item>
     </f7-list>
  </f7-card-content>
      </f7-card>
      <div v-for="section in preferences" :key="section.id">
 <f7-block v-if="section.title|| section.subtitle">
        <p v-if="section.title">{{section.title}}</p>
        <p v-if="section.subtitle"><strong>{{section.subtitle}}</strong></p>
      </f7-block>
      <f7-card class="separatecards">

  <f7-card-content class="margin-bottom" v-for="question in section.questions" :key="question.id">
    <template v-if="question.multiple">
     <div class="list cajabor"  :class="{respondida:researchSurveyAnswers.answers[question.id]||freeTextanswers[question.id]}">
  <ul>
    <f7-list-item divider>{{question.text}}</f7-list-item>
    <li v-for="option in question.options" :key="option.value" :class="option.textArea?'item-content item-input':''">
      <template v-if="option.textArea">
            <div  class="item-media">
              <i class="icon demo-list-icon"></i>
            </div>
            <div class="item-inner">
              <div class="item-title  item-label">{{option.text}}</div>
              <div class="item-input-wrap">
                <input type="text" :placeholder="option.text" @input="answerFreeText(question.id,$event.target.value)" />
                <span class="input-clear-button"></span>
              </div>
            </div>
          </template>
      <label v-else class="item-checkbox item-content">
        <!-- Checkbox input -->
        <input type="checkbox" @change="selectPreference(question.id,option.value,$event.target.checked)" :checked="false"/>
        <!-- Checkbox icon -->
        <i class="icon icon-checkbox"></i>
        <div class="item-inner">
          <!-- Checkbox Title -->
          <div class="item-title">{{option.text}}</div>
        </div>
      </label>
    </li>
  </ul>
     </div>
     </template>
  </f7-card-content>
  
      </f7-card>
      
      </div>
       </f7-col>
     </f7-row>
     
     </div>
       <f7-row  v-if="only!='health'&& Object.values(researchSurvey).length>0" >
    <f7-col class="marginAuto" width="100" medium="80">
     <f7-list v-if="!only" >
   <f7-list-item class="neurita" title="Additional Questions:" >
   </f7-list-item>
 </f7-list>
  <div v-for="section in orderedSections" :key="section.id">
 <f7-block v-if="section.title|| section.subtitle">
        <p v-if="section.title">{{section.title}}</p>
        <p v-if="section.subtitle"><strong>{{section.subtitle}}</strong></p>
      </f7-block>
      <f7-card class="separatecards">

  <f7-card-content class="margin-bottom" v-for="question in section.questions" :key="question.id">
    <template v-if="question.multiple">
     <div class="list cajabor"  :class="{respondida:researchSurveyAnswers.answers[question.id]||freeTextanswers[question.id]}">
  <ul>
    <f7-list-item divider>{{question.text}}</f7-list-item>
    <li v-for="option in question.options" :key="option.value" :class="option.textArea?'item-content item-input':''">
      <template v-if="option.textArea">
            <div  class="item-media">
              <i class="icon demo-list-icon"></i>
            </div>
            <div class="item-inner">
              <div class="item-title  item-label">{{option.text}}</div>
              <div class="item-input-wrap">
                <input type="text" :placeholder="option.text" @input="answerFreeText(question.id,$event.target.value)" />
                <span class="input-clear-button"></span>
              </div>
            </div>
          </template>
      <label v-else class="item-checkbox item-content">
        <!-- Checkbox input -->
        <input type="checkbox" @change="answerResearchSurvey(question.id,option.value,question.multiple,$event.target.checked)" :checked="false"/>
        <!-- Checkbox icon -->
        <i class="icon icon-checkbox"></i>
        <div class="item-inner">
          <!-- Checkbox Title -->
          <div class="item-title">{{option.text}}</div>
        </div>
      </label>
    </li>
  </ul>
     </div>
     </template>
     <template v-else>
  <f7-list media-list class="maslineas">
     <f7-list-item
      class="cajabor" :class="{respondida:researchSurveyAnswers.answers[question.id]}" 
      :title="question.text"
      > <div slot="text" class="margin-top display-flex justify-content-space-between"><span>{{question.options[0].text}}</span><span>{{question.options[question.options.length-1].text}}</span></div>
     <f7-segmented class="btns"  raised tag="p">
    <f7-button v-for="option in question.options" :key="option.value"
       @click="answerResearchSurvey(question.id,option.value)" :active="researchSurveyAnswers.answers[question.id]==option.value">{{option.value}}</f7-button>
  </f7-segmented>
     </f7-list-item>
     </f7-list>
     </template>
  </f7-card-content>
  
      </f7-card>
      
      </div>
    </f7-col>
       </f7-row>
<f7-block >
  <f7-row >
    <f7-col class="marginAuto" width="100" medium="80">
  <p v-if="only!='research'" class="text-align-center">{{!answered?'You need to answer all Physical Activity and Food Intake questions to submit.':'You can submit now'}}</p>
  <f7-button :disabled="!answered" fill raised @click="sinRespuestas()">Submit</f7-button>
    </f7-col>
  </f7-row>
</f7-block>
     
  </f7-block>
  

<f7-block v-show="!hidefollowup" class="marginAuto">

    <f7-card>
    <f7-card-header>
    <p>Thank you for working towards being more active and making healthy food choices! We are checking in to see how <strong>{{lenam}}</strong> is doing with the physical activity and food intake goals provided by <strong>{{clinicianName}}</strong></p>
  </f7-card-header>
  </f7-card>
  <f7-list v-if="physycalString" no-hairlines-md>
      <f7-list-item divider title="Physical Activity">
      </f7-list-item>
  </f7-list>
  <f7-card v-if="physycalString" class="cajabor" :class="{respondida:answers.physical.length>0}">
      <f7-card-header>
      <p>Over the last 4 weeks, how often has {{lenam}} been meeting the physical activity goal of <strong>{{physycalString}}</strong>?</p>
      </f7-card-header>
      <f7-card-content >
        <f7-list >
  <f7-list-item v-for="option in options" :key="option.i"
    radio
    radio-icon="start"
    :title="option.t"
    name="physical"
    @change="selectOption(option.i,'physical')"
  ></f7-list-item>
</f7-list>
      </f7-card-content>
      </f7-card>
      <f7-card v-if="physCheckOp" class="cajabor" :class="{respondida:Object.values(answers.challengesPhy).length>0}">
      <f7-card-header>
      <p>What is your biggest challenge to meeting your physical activity goal?</p>
      </f7-card-header>
      <f7-card-content >
        <f7-list >
  <f7-list-item v-for="option in challenPhys" :key="option.id"
    radio
    name="challengesPhyRadio"
    :title="option.text"
    :checked="answers.challengesPhy.id==option.id"
    @change="selectChallenge({answer:option,type:'challengesPhy'})"
  ></f7-list-item>
  <f7-list-item
  radio
  name="challengesPhyRadio"
  title="Others (please specify)"
  @change="openFreetextInput({type:'challengesPhy'})"

  >
  </f7-list-item>
  <f7-list-input v-show="challengesPhyOpened"
  :value="inputValuePhy"
  @input="setChallengeFreeText({answer:{text:$event.target.value,id:'freeText'},type:'challengesPhy'})"
  placeholder="Others (please specify)"
  clear-button
  >
  </f7-list-input>

</f7-list>
      </f7-card-content>
      </f7-card>
      <f7-list no-hairlines-md>
      <f7-list-item divider title="Food Intake">
      </f7-list-item>
  </f7-list>
  <f7-card v-for="question in foodIntakes" :key="question.id"
      class="cajabor" :class="{respondida:answers[question.id].length>0}">
    <f7-card-header>
      <p>Over the past 4 weeks, how often has {{lenam}} been meeting the <strong>{{question.header}}</strong> goal of <strong>{{currentFoodIntakesGoals[question.id]  }}</strong></p>
    </f7-card-header>
      <f7-card-content >
        <f7-list >
  <f7-list-item v-for="option in options" :key="option.i"
    radio
    radio-icon="start"
    :title="option.t"
    :name="question.id"
    @change="selectOption(option.i,question.id),setShowFood(question.id)"
  ></f7-list-item>
</f7-list>
      </f7-card-content>
      </f7-card>
      <f7-card 
      v-if="foodCheckOp"
       class="cajabor" :class="{respondida:Object.values(answers.challengesFood).length>0}">
      <f7-card-header>
      <p>What is your biggest challenge to meeting your food intake goals</p>
      </f7-card-header>
      <f7-card-content >
        <f7-list >
  <f7-list-item  v-for="option in challenFood" :key="option.id"
    radio
    name="challenFoodRadio"
    :title="option.text"
    :checked="answers.challengesFood.id==option.id"
    @change="selectChallenge({answer:option,type:'challengesFood'})"
  ></f7-list-item>
  <f7-list-item
  radio
  name="challenFoodRadio"
  title="Others (please specify)"
  @change="openFreetextInput({type:'challengesFood'})"
  ></f7-list-item>
  <f7-list-input v-show="challengesFoodOpened"
  :value="inputValueFood"
  @input="setChallengeFreeText({answer:{text:$event.target.value,id:'freeText'},type:'challengesFood'})"
  placeholder="Others (please specify)"
  clear-button
  >
  </f7-list-input>
</f7-list>
      </f7-card-content>
      </f7-card>
      <f7-card 
      v-if="foodCheckOp || physCheckOp"
       class="supportBox" >
      <f7-card-header>
      <p>Do you want more support from your care team with your goals?</p>
      </f7-card-header>
      <f7-card-content >
        <f7-list >
  <f7-list-item
  radio
  name="support"
  title="Yes"
  @change="support=true"
  ></f7-list-item>
  <f7-list-item
  radio
  name="support"
  title="No"
  @change="support=null"
  ></f7-list-item>
</f7-list>
      </f7-card-content>
      </f7-card> 

      <f7-block>
      <p class="text-align-center">{{totalQuestion}}{{answeredtwo}}/{{physycalString?totalQuesYesPhys:totalQuesNoPhys}} answered. {{ (physycalString && answeredtwo==totalQuesYesPhys ) || (!physycalString && answeredtwo==totalQuesNoPhys) ?'You can submit now':'You need to answer all questions to submit.'}}</p>
      <f7-button fill raised @click="send()">Submit</f7-button>
    </f7-block>
</f7-block>

<f7-block v-if="hidesurvey && hidefollowup" class="text-align-center margintopmid">
  PreventTool &copy; {{ new Date().getFullYear() }}
</f7-block>

  </f7-page>
</template>
<script>
export default {
  data(){
    return{
      childOrAdult:null,
      inputValueFood:'',
      inputValuePhy:'',
      hidesurvey:true,
      hidefollowup:true,
      headtitle:'Brief Health Behavior Survey',
      lenam:'the adolescent',
      clinicianName:'clinician',
      freeTextanswers:{},
      researchSurvey:{},
      researchSurveyAnswers:{answers:{},id:''},
      p:null,
      r: null,
      i: 'sur',
      k:null,
      c:null,
      only:null,
      diet:{
      vegetables:{value:null,period:'day'},
      fruits:{value:null,period:'day'},
      grains:{value:null,period:'day'},
      snacking:{value:null,period:'week'},
      beverages:{value:null,period:'week'},
      fastFood:{value:null,period:'week'}
      },
      noVigorous:false,
      noModerate:false,
      moderate:{
         days:0,
         hours:0,
         mins:0,
         minutes:0
      },
      vigorous:{
        days:0,
        hours:0,
         mins:0,
         minutes:0
      },
      smoking:{ value:null,discountCheckbox:null },
      sleep:{ hours:null,minutes:null },
      bloodPressure:{},
      cholesterol:{},
      nicotineExposures:{
        child:{
          3:{text:'Never tried',shortText:'Never'},
          2:{text:'Tried any nicotine product, but more than 30 days ago',shortText:'Tried'},
          1:{text:'Currently vaping (inhaling vapor from e-cigarette or other device)',shortText:'Current use'},
          0:{text:'Current smoker, within last 30 days',shortText:'Current use'}
        },
        adult:{
          4:{text:'Never smoker',shortText:'Never'},
          3:{text:'Former smoker, quit more than 5 years ago',shortText:'Former'},
          2:{text:'Former smoker, quit 1 to 5 years ago',shortText:'Former'},
          1:{text:'Former smoker, quit less than 1 year ago, or currently vaping',shortText:'Former'},
          0:{text:'Current smoker',shortText:'Current use'}
        }
      },
      willingDiet:null,
      willingPhysical:null,
      physicalResourcesPreferences:[],
      foodResourcesPreferences:[],
      socialResourcesPreferences:[],
      activityPreferences:[],
      //de aqui a abajo es de FUP
      challengesPhyOpened:false,
      challengesFoodOpened:false,
      physycalString:null,
      currentFoodIntakesGoals:{},
      physCheckOp:false, //opcion marcada en pregunta physical: true= marco never, rarely o sometimes ; false=opciones restantes
      foodCheckOp:false, //opcion marcada en pregunta de comida: true= marco never, rarely o sometimes ; false=opciones restantes
      textFoodCheckOp:[
      {foodTitle:'Vegetables',flag:false},
        {foodTitle:'Fruits',flag:false},
        {foodTitle:'Grains',flag:false},
        {foodTitle:'Snacking',flag:false},
        {foodTitle:'Sweetened beverages',flag:false},
        {foodTitle:'Fast food',flag:false},
      ],
      totalQuesNoPhys:5,
      totalQuesYesPhys:6,
      answers:{
        physical:'',
        challengesPhy:{},
        challengesFood:{},
        vegetables:'',
        fruits:'',
        grains:'',
        snacking:'',
        beverages:'',
        fastFood:''
      },
      support:null,
      preferences:{
      resources:{
  "id" : "resources",
  "name" : "section1",
  "order" : 1,
  "questions" : {
    physicalResourcesPreferences : {
      "id" : "physicalResourcesPreferences",
      "multiple" : true,
      "options" : [ {
        "order" : 1,
        "text" : "Community/recreation centers",
        "value" : "Community/recreation centers"
      }, {
        "order" : 2,
        "text" : "Health programs (e.g., weight loss and group exercise programs)",
        "value" : "Health programs (e.g., weight loss and group exercise programs)"
      }, {
        "order" : 3,
        "text" : "Parks and playgrounds",
        "value" : "Parks and playgrounds"
      }, {
        "order" : 4,
        "text" : "Sports-specific centers (e.g., club sports facilities, tennis courts)",
        "value" : "Sports-specific centers (e.g., club sports facilities, tennis courts)"
      }, {
        "order" : 5,
        "text" : "Studio fitness (e.g. yoga, Pilates, group fitness classes)",
        "value" : "Studio fitness (e.g. yoga, Pilates, group fitness classes)"
      }, {
        "order" : 6,
        "text" : "WIC clinics (Special Supplemental Nutrition Program for Women, Infants, and Children)",
        "value" : "WIC clinics (Special Supplemental Nutrition Program for Women, Infants, and Children)"
      },
      /*{
        "order" : 7,
        "text" : "Digital resources (e.g., fitness videos, trackers, apps)",
        "value" : "Digital resources (e.g., fitness videos, trackers, apps)"
      }, */
      {
        "order" : 8,
        "text" : "Other (please describe)",
        "textArea" : true,
        "value" : ""
      } ],
      "order" : 1,
      "section" : "-MR30Ns_iFN1mTcXFLb5",
      "text" : "Physical activity (select all that apply)"
    },
    foodResourcesPreferences : {
      "id" : "foodResourcesPreferences",
      "multiple" : true,
      "options" : [ {
        "order" : 1,
        "text" : "Community gardens",
        "value" : "Community gardens"
      }, {
        "order" : 2,
        "text" : " CSA (community supported agriculture organization)",
        "value" : "CSA (community supported agriculture organization)"
      }, {
        "order" : 3,
        "text" : "Farmer’s markets",
        "value" : "Farmer’s markets"
      }, {
        "order" : 4,
        "text" : "Food organizations (includes non-profit food education, community farms, and community outreach organizations)",
        "value" : "Food organizations (includes non-profit food education, community farms, and community outreach organizations)"
      }, {
        "order" : 5,
        "text" : "Food pantries",
        "value" : "Food pantries"
      }, {
        "order" : 6,
        "text" : "Grocery stores",
        "value" : "Grocery stores"
      }, 
      /*{
        "order" : 7,
        "text" : "Digital resources (e.g., cooking videos, educational websites)",
        "value" : "Digital resources (e.g., cooking videos, educational websites)"
      },*/
      {
        "order" : 8,
        "text" : "Other (please describe)",
        "textArea" : true,
        "value" : ""
      } ],
      "order" : 2,
      "section" : "-MR30Ns_iFN1mTcXFLb5",
      "text" : "Healthy eating (select all that apply)"
    },
    socialResourcesPreferences : {
      "id" : "socialResourcesPreferences",
      "multiple" : true,
      "options" : [ {
        "order" : 1,
        "text" : "Clothing and household items",
        "value" : "Clothing and household items"
      }, {
        "order" : 2,
        "text" : "Education",
        "value" : "Education"
      }, {
        "order" : 3,
        "text" : "Employment",
        "value" : "Employment"
      }, {
        "order" : 4,
        "text" : "Health programs",
        "value" : "Health programs"
      }, {
        "order" : 5,
        "text" : "Housing and utilities",
        "value" : "Housing and utilities"
      }, {
        "order" : 6,
        "text" : "Income support",
        "value" : "Income support"
      }, {
        "order" : 7,
        "text" : "Individual and family support",
        "value" : "Individual and family support"
      }, {
        "order" : 8,
        "text" : "Religious organizations",
        "value" : "Religious organizations"
      },
      {
        "order" : 9,
        "text" : "Transportation",
        "value" : "Transportation"
      },    
      /*{
        "order" : 10,
        "text" : "Digital resources (e.g., cooking videos, educational websites)",
        "value" : "Digital resources (e.g., cooking videos, educational websites)"
      },*/
      {
        "order" : 11,
        "text" : "Other (please describe)",
        "textArea" : true,
        "value" : ""
      } ],
      "order" : 3,
      "section" : "-MR30Ns_iFN1mTcXFLb6",
      "text" : "Social resources (select all that apply)"
    }
  },
  "subtitle" : "The PREVENT tool provides an interactive resource map to help you locate services and resources in your community to support your health. Please select the types of resources you would like more information about:",
  "title" : "Community Resource Preferences:"
},
      activities:{
  "id" : "activities",
  "name" : "section2",
  "order" : 2,
  "questions" : {
    activityPreferences : {
      "id" : "activityPreferences",
      "multiple" : true,
      "options" : [ {
        "order" : 1,
        "text" : "Basketball",
        "value" : "Basketball"
      }, {
        "order" : 2,
        "text" : "Interactive video game",
        "value" : "Interactive video game"
      }, {
        "order" : 3,
        "text" : "Going for a walk",
        "value" : "Going for a walk"
      }, {
        "order" : 4,
        "text" : "Dancing",
        "value" : "Dancing"
      }, {
        "order" : 5,
        "text" : "Skate Boarding",
        "value" : "Skate Boarding"
      }, {
        "order" : 6,
        "text" : "Field sports (football, hockey, soccer, etc.)",
        "value" : "Field sports (football, hockey, soccer, etc.)"
      }, {
        "order" : 7,
        "text" : "Gymnastics/cheerleading",
        "value" : "Gymnastics/cheerleading"
      }, {
        "order" : 8,
        "text" : "Swimming",
        "value" : "Swimming"
      }, {
        "order" : 9,
        "text" : "Bicycling",
        "value" : "Bicycling"
      }, {
        "order" : 10,
        "text" : "Other (please describe)",
        "textArea" : true,
        "value" : ""
      } ],
      "order" : 1,
      "section" : "-MR5nDsZzhLutiFglLxl",
      "text" : "Physical Activity Preferences:"
    }
  },
  "subtitle" : "From this list, please select up to three activities you like to do to be active? If your favorite activities are not listed, please let us know using the “other” selection.",
  "title" : "Physical Activity Preferences:"
}
},
      options:[
        {i:'Never',t:'Never'},
        {i:'Rarely',t:'Rarely'},
        {i:'Sometimes',t:'Sometimes'},
        {i:'Often',t:'Often'},
        {i:'Always',t:'Always'},
      ],
      challenPhys:[
        {text:'Feeling motivated',id:0},
        {text:'Not making physical activity a priority right now',id:1},
        {text:'Having energy',id:2},
        {text:'Not having time',id:3},
        {text:'Thinking of any physical activities that I want to do',id:4},
        {text:'Having access to the equipment I need to participate in my preferred physical activity',id:5},
        {text:'Feeling unsafe using the outdoor spaces in my neighborhood',id:6},
      ],
      challenFood:[
        {text:'Being in the mood to eat the green (anytime) foods',id:0},
        {text:'Preferring the yellow (sometimes) and red (rarely) foods',id:1},
        {text:'Having time to prepare the green (anytime) foods',id:2},
        {text:'Knowing how to prepare the green (anytime) foods',id:3},
        {text:'The cost of green (anytime) foods',id:4},
        {text:'Finding the green (anytime) foods in the store where I usually buy my groceries',id:5},
      ],
    }
  },
  computed:{
    foodIntakes(){
       return{ 
        vegetables:{
          id:'vegetables',
          header:'Vegetables',
          question:'How many servings of vegetables do you consume?',
          serving:'1 serving= ½ cup raw or cooked vegetables, 2 cups leafy salad greens'
        },
        fruits:{
          id:'fruits',
          header:'Fruits',
          question:'How many servings of fruit do you consume?',
          serving:'1 serving= ½ cup, 1 medium fruit'
        },grains:{
          id:'grains',
          header:'Grains',
          question:'How many servings of whole grains do you consume?',
          serving:'1 serving= 1 slice of bread, ½ cup cooked, 1 cup dry cereal'
        },snacking:{
          id:'snacking',
          header:'Snacking',
          question:'How many servings of pre-packaged, processed snacks (e.g., candy, bars, pastries, chips, cookies or butter crackers) do you consume?',
          serving:'1 serving= 1 item, bar or slice'
        },
        beverages:{
          id:'beverages',
          header:'Beverages',
          question:'How many servings of sugar sweetened beverages do you drink?',
          serving:`1 serving= ${this.childOrAdult=='child'?8:12} oz, (not including diet soda)`
          },
        fastFood:{
          id:'fastFood',
          header:'Fast food',
          question:'How many times do you eat meals from fast food restaurants?'
        }
      }
    },
    minutesEvery5(){
      let array=[]
      for (let i = 0; i<12; i++) {
      array.push(i*5)
}
return array
    },
    totalQuestion(){
      if (this.physycalString){
        if ((this.physCheckOp) && (this.foodCheckOp))
          this.totalQuesYesPhys=9;
        else
          this.totalQuesYesPhys=7;
        if ((!this.physCheckOp) && (this.foodCheckOp))
          this.totalQuesYesPhys=8;
        if ((this.physCheckOp) && (!this.foodCheckOp))
          this.totalQuesYesPhys=8;
      }else{
        if (this.foodCheckOp)
          this.totalQuesNoPhys=7;
        else
          this.totalQuesNoPhys=6;
      }

    },
    arrayNamesGoals(){
      let arr=[];
        for (let i=0;i<this.textFoodCheckOp.length;i++){
          if (this.textFoodCheckOp[i].flag){
            arr.push(this.textFoodCheckOp[i].foodTitle);
          }
        }
        if (arr.length === 0) {
    return "";
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr.join(" and ");
  } else {
    let lastElement = arr.pop();
    return arr.join(", ") + " and " + lastElement;
  }
    },
    answered(){
    if(this.only=='research')return true
     if(Object.values(this.diet).some(foodIntake=>{
        return !foodIntake.value && foodIntake.value!=0
      })) return 
      if(!this.noModerate && (this.moderate.days==0 ||(this.moderate.hours==0 && this.moderate.minutes==0))) return
      //if(!this.noVigorous && (this.vigorous.days==0 ||(this.vigorous.hours==0 && this.vigorous.minutes==0))) return
      if(this.smoking.value==null) return
      if(this.sleep.hours==null && this.sleep.minutes==null ) return
      if(Object.keys(this.bloodPressure).length==0) return
      if(Object.keys(this.cholesterol).length==0) return
      if(!this.willingDiet)return 
      if(!this.willingPhysical)return 
      	return true
    },
    answeredtwo(){
      let cuants=0;
    let llaves=Object.keys(this.answers) || [];
      llaves.forEach(unakey => {
        if(typeof this.answers[unakey] != 'object'){
          if( this.answers[unakey]!='' ){
            cuants++;
          }
        }
        else {
          if(Object.keys(this.answers[unakey]).length>0 ){
              if(unakey=='challengesFood'&& this.foodCheckOp ){
                if(this.challengesFoodOpened && this.answers[unakey].id=='freeText' && this.answers[unakey].text ){
                  cuants++;
                }
                else if(!this.challengesFoodOpened) cuants++;
              } 
              else if(unakey=='challengesPhy'&& this.physCheckOp){
                if(this.challengesPhyOpened && this.answers[unakey].id=='freeText' && this.answers[unakey].text ){
                  cuants++;
                }
                else if(!this.challengesPhyOpened) cuants++;
              } 
            
          }
          }
      });
      
      return cuants
    },
    orderedSections(){
    return  Object.values(this.researchSurvey.sections).sort((a,b)=>{
            var x = a.order;
            var y = b.order;
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        });
    },
  },
  mounted(){
    this.pickerVigorousDays();
    this.pickerVigorousHours();
    this.pickerModerateDays();
    this.pickerModerateHours();

    var url_string = window.location.href
    var url = new URL(url_string);
     this.p = url.searchParams.get("p");
     if(this.p){
       this.p=this.p.replaceAll('?','_')
       this.p=this.p.replaceAll('¿','_')
     }
      this.r = url.searchParams.get("r");
      if(this.r){
       this.r=this.r.replaceAll('?','_')
       this.r=this.r.replaceAll('¿','_')
     }
        this.i = url.searchParams.get("i") || 'sur';
        this.k=url.searchParams.get("k");
        this.c=url.searchParams.get("c");
        if(this.c){
       this.c=this.c.replaceAll('?','_')
       this.c=this.c.replaceAll('¿','_')
     }
        this.only=url.searchParams.get("only");
    if(this.i=='sur' && this.p  ){
      this.validate();
    }
    else if(this.i=='fup' && this.p && this.r ){
      this.headtitle='Follow-up on achieved goals';
      this.validate();
    }
    else{
      this.$f7.dialog.alert('You did not provide parameters to validate your identity.','Missing information');
      this.hidesurvey=true;
    }
  },
  methods:{
    setSleepingValues({value,type}){
       this.sleep[type]=Number(value)
      },
    setDiscountCheckbox({checked,wich}){
      if(checked)this[wich].discountCheckbox=true
      else this[wich].discountCheckbox=null
      this[wich]=Object.assign({},this[wich])
    },
    setNicotineExposureValue({value}){
      if(value)this.smoking.value = Number(value);
      else this.smoking.value =null
    },
    setDietSertvingsValue({value,wich}){
      this.diet[wich].value=Number(value)
    },
    setDietPeriodValue({value,wich}){
      this.diet[wich].period=value
    },
    setVigorousOrModerateValue({vigorousOrModerate,value,which}) {
      this[vigorousOrModerate][which] = Number(value);
      this[vigorousOrModerate]=Object.assign({},this[vigorousOrModerate])
    },
    setChallengeFreeText({answer,type}){
      if(type=='challengesFood')this.inputValueFood=answer.text
        else if(type=='challengesPhy')this.inputValuePhy=answer.text
        
      this.selectChallenge({answer,type})
    },
    openFreetextInput({type}){
    this[`${type}Opened`]=true
    if(type=='challengesFood')this.answers[type]=Object.assign({},{id:'freeText',text:this.inputValueFood})
    else this.answers[type]=Object.assign({},{id:'freeText',text:this.inputValuePhy})
    },
    selectChallenge({answer,type}){
      if(answer.id!='freeText'){
        this[`${type}Opened`]=false
      }
        this.answers[type]=Object.assign({},answer)
      this.answers=Object.assign({},this.answers)
    },
    answerResearchSurvey(q,answer,multiple,checked){
      let local=this.researchSurveyAnswers
      if(multiple){
        !local.answers[q]?local.answers[q]={multiple:[]}:""
        if(checked){
          local.answers[q].multiple.push(answer)
        }else{
          local.answers[q].multiple.splice(local.answers[q].multiple.indexOf(answer),1)
          if(local.answers[q].multiple.length==0){
        delete local.answers[q]
      }
        }
      }else{
      local.answers[q]=answer
      }
      this.researchSurveyAnswers=Object.assign({},local)
    },
    answerFreeText(q,answer){
      if(answer){
      let local=Object.assign({},this.freeTextanswers)
      local[q]=answer
      this.freeTextanswers=Object.assign({},local)
      }else{
        delete this.freeTextanswers[q]
      }
      
      
    },
      selectOption(option,q,freeText){ 
        if(q=='challengesFood' ||  q=='challengesPhy'){

        }
        if(!freeText && q=='challengesFood') this.inputValueFood=''
        if(!freeText && q=='challengesPhy') this.inputValuePhy=''
      this.answers[q]=option;
      this.checkOption()
      console.log('selectOption',this.answers)
    },
    send(){
      if(this.physycalString && this.answers.physical.length==0) return this.$f7.dialog.alert('The Physical Activity question has not been answered');
      if(this.physycalString && this.physCheckOp  && (Object.keys(this.answers.challengesPhy).length==0 ||(Object.keys(this.answers.challengesPhy).length==1 && !this.challengesPhyOpened && this.answers.challengesPhy.freeText && this.answers.challengesPhy.freeText.length>0  ))) return this.$f7.dialog.alert('The Physical Activity Goal question has not been answered');
      if(this.answers.fruits.length==0) return this.$f7.dialog.alert('The question about fruits from food intakes has not been answered');
      if(this.answers.vegetables.length==0) return this.$f7.dialog.alert('The question about vegetables from food intakes has not been answered');
      if(this.answers.grains.length==0) return this.$f7.dialog.alert('The question about grains from food intakes has not been answered');
      if(this.answers.beverages.length==0) return this.$f7.dialog.alert('The question about sweetened beverages from food intakes has not been answered');
      if(this.answers.snacking.length==0) return this.$f7.dialog.alert('The question about snacking meals from food intakes has not been answered');
      if(this.answers.fastFood.length==0) return this.$f7.dialog.alert('The question about fast food meals from food intakes has not been answered');
      if(this.foodCheckOp && (Object.keys(this.answers.challengesFood).length==0 ||(Object.keys(this.answers.challengesFood).length==1 && !this.challengesFoodOpened && this.answers.challengesFood.freeText && this.answers.challengesFood.freeText.length>0 ))) return this.$f7.dialog.alert('The question Food challenges from food intakes has not been answered');
        if(!this.foodCheckOp)delete this.answers.challengesFood
        if(!this.physCheckOp)delete this.answers.challengesPhy
               this.$f7.dialog.preloader('Saving your answers...');
                let requesthandler = this.$firebase.functions().httpsCallable('requesthandler');
                requesthandler({p:this.p,r:this.r,i :this.i,k:this.k,c:this.c,answers:this.answers,support:this.support}).then((ladata)=> {
                  this.$f7.dialog.close();
                  console.log(ladata);
                  if(ladata.data.fail){
                    throw new Error(ladata.data.datum);
                  }
                  else{
                    this.hidefollowup=true;
                    console.log('All good!');
                    this.$f7.dialog.confirm('You will receive an email from us shortly with additional instructions.','Thank you for your response!');
                    window.open(ladata.data.datum,"_self");  
                  }
                }).catch((error)=>{
                  console.log('error en fx',error);
                  this.$f7.dialog.close();
                  this.$f7.dialog.alert(error.message,'Error');
                });
    },
    validate(){
       this.$f7.dialog.preloader('Validating user info...');
         let requesthandler = this.$firebase.functions().httpsCallable('requesthandler');
        requesthandler({p:this.p, r:this.r, i:this.i,k:this.k,c:this.c,only:this.only}).then((ladata)=> {
          this.$f7.dialog.close();
          console.log(ladata);
          if(ladata.data.fail){
            throw new Error(ladata.data.datum);
          }
          else{
            if(this.i=='fup'){
               this.hidefollowup=false;
                this.lenam=ladata.data.datum.lename || 'the adolescent';
                 this.clinicianName=ladata.data.datum.clinicianName || 'the clinician';
                this.physycalString=ladata.data.datum.stringToSend?ladata.data.datum.stringToSend.str :null
                this.currentFoodIntakesGoals=Object.assign(ladata.data.datum.currentFoodIntakesGoals)
                if(this.physycalString){
                let changeIncluding=this.physycalString.replace('Include','Including')
                this.physycalString=changeIncluding
                }
               
            }
            else{
              this.hidesurvey=false;
              this.lenam=ladata.data.datum.lename || 'the adolescent';
              this.childOrAdult=ladata.data.datum.childOrAdult
              if(this.only=='research')this.headtitle='Prevent research survey'
              this.researchSurvey=Object.assign({},ladata.data.datum.researchSurvey||{})
            }
           console.log('All good!');
          }
        }).catch((error)=>{
           this.hidesurvey=true;
           this.hidefollowup=true;
          console.log('error en fx',error);
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,'Error');
        });
    },
    sinRespuestas(){
      if(this.only=='research'&&Object.values(this.researchSurveyAnswers.answers).length==0){
        this.$f7.dialog.confirm(`You haven't answered any questions, do you want to send it anyway?`,()=>{this.enviarForma()});
                }else{
                  this.enviarForma()
                }
    },
    enviarForma(){
      let tosave
      let params={p:this.p,r:this.r,c:this.c}
      if(this.only!='research'){
      if(!this.answered){
       return  this.$f7.dialog.alert('You must answer all items');
      }
        if(this.noVigorous){
          this.vigorous={
           days:0,
          hours:0,
           minutes:0,
            }
        }
        if(this.noModerate){
          this.moderate={
           days:0,
          hours:0,
          minutes:0,
            }
        }
        if(this.freeTextanswers.physicalResourcesPreferences){
          this.physicalResourcesPreferences.push(this.freeTextanswers.physicalResourcesPreferences)
          delete this.freeTextanswers.physicalResourcesPreferences
          }
        if(this.freeTextanswers.foodResourcesPreferences){
          this.foodResourcesPreferences.push(this.freeTextanswers.foodResourcesPreferences)
          delete this.freeTextanswers.foodResourcesPreferences
          }
          if(this.freeTextanswers.socialResourcesPreferences){
          this.socialResourcesPreferences.push(this.freeTextanswers.socialResourcesPreferences)
          delete this.freeTextanswers.socialResourcesPreferences
          }
        if(this.freeTextanswers.activityPreferences){
          this.activityPreferences.push(this.freeTextanswers.activityPreferences)
          delete this.freeTextanswers.activityPreferences  
          }
           tosave={
            vegetables:this.diet.vegetables,
            fruits:this.diet.fruits,
            grains:this.diet.grains,
            snacking:this.diet.snacking,
            beverages:this.diet.beverages,
            fastFood:this.diet.fastFood,
            noVigorous:this.noVigorous||this.vigorous.days==0,
            noModerate:this.noModerate||this.noModerate.days==0,
            moderate:this.moderate,
            vigorous:this.vigorous,
            smoking:this.smoking,
            sleep:this.sleep,
            bloodPressure:this.bloodPressure,
            cholesterol:this.cholesterol,
            willingPhysical:this.willingPhysical,
            willingDiet:this.willingDiet,
            physicalResourcesPreferences:this.physicalResourcesPreferences,
            foodResourcesPreferences:this.foodResourcesPreferences,
            socialResourcesPreferences:this.socialResourcesPreferences,
            activityPreferences:this.activityPreferences,
            };
            params.answers=tosave
            }
            
               this.$f7.dialog.preloader('Saving your answers...');
                let requesthandler = this.$firebase.functions().httpsCallable('requesthandler');

                if(this.only!='health'){ 
                  this.researchSurveyAnswers.id=this.researchSurvey.id
                  if(Object.values(this.freeTextanswers).length>0){
                  Object.keys(this.freeTextanswers).forEach(answerid=>{
                    if(!this.researchSurveyAnswers.answers[answerid]){
                      this.researchSurveyAnswers.answers[answerid]={freeText:this.freeTextanswers[answerid]}
                    }else{
                      this.researchSurveyAnswers.answers[answerid].freeText=this.freeTextanswers[answerid]
                    }
                  })
                  }
                  params.researchSurveyAnswers=this.researchSurveyAnswers
                }
                requesthandler(params).then((ladata)=> {
                  this.$f7.dialog.close();
                  console.log(ladata);
                  if(ladata.data.fail){
                    throw new Error(ladata.data.datum);
                    
                  }
                  else{
                    this.hidesurvey=true;
                    console.log('All good!');
                    this.$f7.dialog.alert('Your answers were saved correctly','Success');
                  }
                }).catch((error)=>{
                  this.hidesurvey=false;
                  console.log('error en fx',error);
                  this.$f7.dialog.close();
                  this.$f7.dialog.alert(error.message,'Error');
                  
                });

    },
    dietQuestions(q,value){
      this[q]=value
    },
    pickerModerateDays(){
      let picker  = this.$f7.picker.create({
  inputEl: '#moderate-days',
  rotateEffect: true,
  cols: [
    {
      textAlign: 'center',
      values: [1, 2, 3, 4, 5, 6, 7]
    }
  ],
   on: {
    change:  (picker, values)=> {
        this.moderate.days=values[0];
      }
    },
});
    },
    pickerModerateHours(){
      let picker  = this.$f7.picker.create({
  inputEl: '#moderate-hours',
  rotateEffect: true,
  formatValue: function (values, displayValues) {
    return values[0] + ':' + values[1];
  },
  cols: [
     {
      values: (function () {
        var arr = [];
        for (var i = 0; i <= 23; i++) { arr.push(i); }
          return arr;
      })(),
    },
    // Divider
    {
      divider: true,
      content: ':'
    },
    // Minutes
    {
      values: (function () {
        var arr = [];
        for (var i = 0; i <= 59; i++) { arr.push(i < 10 ? '0' + i : i); }
          return arr;
      })(),
    }
  ],
   on: {
    change:  (picker, values)=> {
        this.moderate.hours=values[0];
        this.moderate.mins=values[1];
      }
    },
});
    },
    
    pickerVigorousDays(){
      this.vigorousPicker  = this.$f7.picker.create({
  inputEl: '#vigorous-days',
  rotateEffect: true,
  cols: [
    {
      textAlign: 'center',
      values: [1, 2, 3, 4, 5, 6, 7]
    }
  ],
  on: {
    change:  (picker, values)=> {
        this.vigorous.days=values[0];
      }
    },

});
    },
    pickerVigorousHours(){
      let picker  = this.$f7.picker.create({
  inputEl: '#vigorous-hours',
  rotateEffect: true,
  formatValue: function (values, displayValues) {
    return values[0] + ':' + values[1]
  },
  cols: [
     {
      values: (function () {
        var arr = [];
        for (var i = 0; i <= 23; i++) { arr.push(i); }
          return arr;
      })(),
    },
    // Divider
    {
      divider: true,
      content: ':'
    },
    // Minutes
    {
      values: (function () {
        var arr = [];
        for (var i = 0; i <= 59; i++) { arr.push(i < 10 ? '0' + i : i); }
          return arr;
      })(),
    }
  ],
  on: {
    change:  (picker, values)=> {
        this.vigorous.hours=values[0];
        this.vigorous.mins=values[1];
      }
    },
});
    },
    checkOption(){
          if(this.answers.physical=='Never' ||this.answers.physical=='Rarely'||this.answers.physical=='Sometimes'){
        this.physCheckOp=true;
        }else{
        this.physCheckOp=false;
          }
      let dietones=Object.keys(this.foodIntakes)
     let wrongAnswer = dietones.some(element =>{return this.answers[element]=='Never' ||this.answers[element]=='Rarely'||this.answers[element]=='Sometimes' })
      
      if(wrongAnswer){
        this.foodCheckOp=true
      }else{
        this.foodCheckOp=false;
      }
    },
    setShowFood(q){
      let flag=false;
      Object.values(this.foodIntakes).forEach(element =>{
        if(q==element.id){
          if ((this.answers[element.id]!='Often') && (this.answers[element.id]!='Always')){
            for (let i=0;i<this.textFoodCheckOp.length;i++){
              if (this.textFoodCheckOp[i].foodTitle==element.header)
                this.textFoodCheckOp[i].flag=true;
            }
          }else{
            for (let i=0;i<this.textFoodCheckOp.length;i++){
              if (this.textFoodCheckOp[i].foodTitle==element.header)
                this.textFoodCheckOp[i].flag=false;
            }
          }
        }
      })
    },
    selectPreference(typeOfPreference,option,selected){
      console.log(typeOfPreference)
      if(selected)this[typeOfPreference].push(option)
      else this[typeOfPreference].splice(this[typeOfPreference].indexOf(option),1)

      console.log(this[typeOfPreference])
      }
}
}
</script>
<style>
.buttonSize{
  width: 180px !important;
}
.days-picker input{
  text-align: center
}
.separatecards{
margin-bottom: 30px!important;
}
.soyblock{
display:block!important;
}
.neurita .item-title{
  font-weight: bold;
    font-size: 20px;
}
.neurita .item-after{
  font-size: 22px;
    color: #202020;
}
.list.maslineas .item-title {
  white-space: unset!important;
}
.nextToNumber .item-content{
  padding-left:0px!important;
}

.bluebellow{
  border-bottom: 3px solid #b2d1ff;
}

.cajabor{
 border-left: 6px solid #ca8811;
 background: #f3f3f3;
}
.supportBox{
 background: #f3f3f3;
}
.cajabor input{
  background: #fff!important;
    border: 1px solid #377afe;
    border-radius: 10px;
}
.respondida{
  border-left-color: #2f82fe!important;
}
.list.maslineas .item-after p{
  width: 130px!important;
}
.margintopmid{
  margin-top:30%;
}
.sinwhitespace .item-title{
  white-space: unset!important;
}
.break-spaces  .item-subtitle {
  white-space: break-spaces !important;
}
.marginAuto{
  margin: auto !important;
}
</style>