<template>
  <f7-app :params="f7params" >
  <f7-view main class="safe-areas" url="/form/"></f7-view>

  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';

  export default {
    data() {
      return {
        f7params: {
          name: 'Health Behaviour Survey - PreventTool',
          theme: 'md',
          routes: routes,
        },
      }
    },
    mounted() {
      this.$f7ready((f7) => {

        // Call F7 APIs here
      });
    }
  }
</script>