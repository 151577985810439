// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';

import firebase from 'firebase/app'
import 'firebase/functions';

let firebaseConfig = {
  apiKey: "AIzaSyBSmVysoXaCuv53N4WjifPLusXHXE12HXw",
  authDomain: "prevent-tool-pro.firebaseapp.com",
  databaseURL: "https://prevent-tool-pro.firebaseio.com",
  projectId: "prevent-tool-pro",
  storageBucket: "prevent-tool-pro.appspot.com",
  messagingSenderId: "535301119582",
  appId: "1:535301119582:web:e3abf5b49df4252a2c1ecf",
  measurementId: "G-ZKY5XMYY3K"
};
/*
// dev
let firebaseConfig = {
  apiKey: "AIzaSyBbkBytcQToCyBQpAYFoDzxfbnNzQ-dASk",
  authDomain: "dev-prevent-tool.firebaseapp.com",
  databaseURL: "https://dev-prevent-tool.firebaseio.com", 
  projectId: "dev-prevent-tool",
  storageBucket: "dev-prevent-tool.appspot.com",
  messagingSenderId: "765301795969",
  appId: "1:765301795969:web:258e14a8c257b9cda7b180",
  measurementId: "G-J20BNL6VY4"
};
/* emulator 
firebaseConfig={
  apiKey: "AIzaSyBSmVysoXaCuv53N4WjifPLusXHXE12HXw",
  projectId: 'prevent-tool-pro',
  databaseURL: "http://localhost:9000/?ns=prevent-tool-pro"
}
*/
firebase.initializeApp(firebaseConfig);
//firebase.functions().useFunctionsEmulator('http://localhost:5001');

Vue.prototype.$firebase=firebase;
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
});